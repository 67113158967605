/* eslint-disable react/prop-types,react/display-name */
import React from 'react';

import { DefaultLayout } from '../layout';
import { ContrastThemeProvider } from '../contrastTheme';
import { Seo } from '../ui';
import {
	ContactSection,
	DifferencesSection,
	DrExplanationSection,
	DrProcessSection,
	DrTimelineSection,
	DrVideoSection,
	HeroSection,
	ProductsSection,
	ReferencesSection,
} from '../view/landing';

const Index = props => (
	<DefaultLayout {...props}>
		<Seo title="AI Deep recommendation" />

		<HeroSection sx={{ zIndex: 1 }} {...props} />

		<DrVideoSection sx={{ zIndex: 2 }} {...props} />

		<DrExplanationSection variant="stripesPrimary" />

		<DrTimelineSection variant="stripesSecondary" />

		<DifferencesSection variant="stripesPrimary" {...props} />

		<DrProcessSection variant="stripesSecondary" {...props} />

		<ContrastThemeProvider>
			<ReferencesSection {...props} />
		</ContrastThemeProvider>

		<ProductsSection variant="stripesSecondary" />

		<ContactSection {...props} />
	</DefaultLayout>
);

export default Index;
